<template>
  <v-container class="container pa-0">
    <v-card
      class="mx-auto pt-4 pb-4 shopmall-updatepassword"
      :elevation="0"
      max-width="648"
      rounded="sm"
    >
      <h3 class="txt-aligin pt-6 pb-4">Update Your Password</h3>
      <p class="fs-14 fc-black-06">
        Dear valued customer, we're excited to share that we've upgraded our
        system to serve you better! As part of this enhancement, we kindly
        request that you update your password.
      </p>
      <p class="fs-14 fc-black-06">
        Thank you for your cooperation and understanding.
      </p>
      <v-form ref="formRef" @submit.prevent="submit">
        <v-row>
          <v-col cols="12">
            <v-text-field
              class="mt-8"
              label="New Password"
              v-model.trim="form.password"
              :rules="rules.password"
              :append-inner-icon="visible ? 'mdi-eye' : 'mdi-eye-off'"
              :type="visible ? 'text' : 'password'"
              @click:append-inner="visible = !visible"
              prepend-inner-icon="mdi-asterisk"
            ></v-text-field>

            <!-- 密码校验 -->
            <shopmall-password-rule ref="passwordRef" :colsNum="colsNum" />
          </v-col>
          <v-col cols="12">
            <v-text-field
              class="mt-2"
              label="Confirm New Password"
              v-model.trim="form.newPassword"
              :rules="rules.newPassword"
              :append-inner-icon="confirmVisible ? 'mdi-eye' : 'mdi-eye-off'"
              :type="confirmVisible ? 'text' : 'password'"
              @click:append-inner="confirmVisible = !confirmVisible"
              prepend-inner-icon="mdi-asterisk"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-btn
              type="submit"
              color="primary"
              block
              size="large"
              :loading="loading"
              flat
              >Save Password</v-btn
            >
          </v-col>
          <v-col class="txt-aligin pt-1 pb-0">
            <span class="skip fc-black-06 fs-14" @click="skipFn">Skip</span>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-container>
</template>
<script setup>
import { systemInteractor } from "~/core";
const emit = defineEmits(["skipFn"]);
const { $Message, $validate } = useNuxtApp();
const passwordRef = ref(null);
const formRef = ref(null);
const visible = ref(false);
const confirmVisible = ref(false);
const form = ref({
  password: "",
  newPassword: ""
});
const loading = ref(false);
const rules = ref({
  password: [v => $validate.Password(v, true)],
  newPassword: [v => $validate.ConfirmPassword(v, true, form.value.password)]
});
const colsNum = ref(6);
// 监听密码提示
watch(
  () => form.value.password,
  val => {
    passwordRef.value.passwordRules(val || "");
  }
);
// 自适应窗口大小
window.onresize = () => {
  getInnerWidth();
};
const getInnerWidth = () => {
  const bodyWidth = window.innerWidth;
  console.log(bodyWidth);
  if (bodyWidth > 600) {
    colsNum.value = 6;
  } else {
    colsNum.value = 12;
  }
};
onMounted(() => {
  getInnerWidth();
});
// 点击确认
const submit = async () => {
  const { valid } = await formRef.value.validate();
  if (valid) {
    const [res, err] = await systemInteractor.changePasswordEmail(form.value);
    if (!err) {
      $Message.success("Password reset completed");
      emit("skipFn");
    }
  }
};
// skip事件
const skipFn = () => {
  formRef.value.reset();
  emit("skipFn");
};
defineExpose({
  skipFn
});
</script>
<style scoped lang="scss">
.container {
  margin-top: -35px;
  .shopmall-updatepassword {
    .txt-aligin {
      text-align: center;
    }
    .skip {
      cursor: pointer;
      text-decoration: underline;
      // display: inline-block;
      // border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    }
  }
}
</style>
